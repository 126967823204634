import FILTERS_CONFIG from '@/model/modules/ecommerce/filtersConfig';
import Column from '@/model/shared/Column';

export default {
  headers: [
    new Column('NAME', 'name').asSort().setClass('font-bold truncate'),
    new Column('CATEGORY', 'category.name').asSort(),
    new Column('SUBCATEGORY', 'subcategory.name').asSort(),
    new Column('APPLIED TO OFFERS', 'activeOffersCount').setClass('text-center'),
    new Column('ACTIONS', 'actions').setClass('text-center w-4'),
  ],
  filters: [FILTERS_CONFIG.NAME, FILTERS_CONFIG.CATEGORY_ID, FILTERS_CONFIG.SUB_CATEGORY_ID],
};
