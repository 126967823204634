var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("meta-actions-header", {
        staticClass: "mb-4",
        attrs: { "is-loading": _vm.isLoading },
        scopedSlots: _vm._u(
          [
            !_vm.isLoading
              ? {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "breadcrumb",
                        { staticClass: "flex flex-1 text-gray-700" },
                        [
                          _c("span", [_vm._v(": ")]),
                          _vm.advertiser
                            ? _c("span", { staticClass: "text-orange-500" }, [
                                _vm._v(_vm._s(_vm.advertiser.name)),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c(
        "div",
        {
          staticClass:
            "flex flex-col p-6 text-left bg-white rounded rounded-lg shadow-md lg:p-12",
        },
        [
          _vm.isLoading || !_vm.advertiser
            ? _c("card-form-loading", {
                staticClass: "mt-10",
                attrs: { rows: 2 },
              })
            : _c(
                "sun-form",
                {
                  on: {
                    submit: _vm.submitForm,
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.submitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "card-form",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(" Basic Info"),
                      ]),
                      _c(
                        "template",
                        { slot: "form" },
                        [
                          _c("form-row", {
                            scopedSlots: _vm._u([
                              {
                                key: "left",
                                fn: function () {
                                  return [
                                    _c(
                                      "sun-label-group",
                                      { attrs: { text: "Name" } },
                                      [
                                        _c("asterix-input", {
                                          attrs: {
                                            id: "advertiser-name",
                                            type: "text",
                                            name: "name",
                                            minlength: 3,
                                            maxlength: 50,
                                            placeholder: "3GFactor",
                                            required: "required",
                                          },
                                          model: {
                                            value: _vm.advertiser.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.advertiser,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "advertiser.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c("category-selector", {
                            model: {
                              value: _vm.advertiser,
                              callback: function ($$v) {
                                _vm.advertiser = $$v
                              },
                              expression: "advertiser",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "flex w-1/2 mt-12" },
                    [
                      _c("save-button", {
                        attrs: {
                          id: "advertiser-submit",
                          loading: _vm.isLoading,
                        },
                      }),
                      _c(
                        "sun-popover",
                        {
                          attrs: {
                            "text-info":
                              "It is not possible to delete the selected advertiser entity because it is associated with an offer.",
                            trigger: !_vm.canBeDeleted ? "hover click" : "null",
                          },
                        },
                        [
                          _vm.advertiserId
                            ? _c(
                                "sun-button",
                                {
                                  staticClass: "px-6 ml-4 text-sm custom-p-1",
                                  attrs: {
                                    color: "red",
                                    variant: "pill",
                                    disabled:
                                      _vm.isLoading || !_vm.canBeDeleted,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showDeleteModal = true
                                    },
                                  },
                                },
                                [_vm._v(" Delete ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c("delete-modal", {
        attrs: { open: _vm.showDeleteModal },
        on: {
          cancel: function ($event) {
            _vm.showDeleteModal = false
          },
          confirm: _vm.deleteAdvertiser,
        },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function () {
              return [_vm._v(_vm._s(_vm.deleteAdvertiserText))]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }