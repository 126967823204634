<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #header-right>
        <router-link :to="newAdvertiserRoute">
          <sun-button variant="pill" class="px-4 text-xs bg-gray-700 custom-p-1 hover:bg-gray-800" color="gray">
            + New Advertiser
          </sun-button>
        </router-link>
      </template>

      <template #content>
        <sun-filter-layout
          id="filter-box"
          :filters-added="filterFind"
          :filters-available="availableFilters"
          :has-error="anyError"
          show-reset
          @change="filtersSetFiltersFind"
          @remove="filtersResetErrors"
          @reset="resetFilters"
        >
          <template #quickFilters>
            <div class="min-w-max">
              <sun-search-input class-input="text-sm" :value="filterQuick.name" @search="onQuickFiltersSearch" />
            </div>
          </template>
          <!--Filters Slot :Start -->
          <template #[`filter.name`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.category.id`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              :options="categoriesList"
              close-on-select
              disable-selected-options
              track-by="value"
              label="name"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.subcategory.id`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              :options="categoriesList"
              close-on-select
              disable-selected-options
              track-by="id"
              label="name"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24 cursor-pointer"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              group-label="name"
              group-values="subcategories"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
        </sun-filter-layout>

        <sun-data-table
          :headers="headers"
          :content="items"
          hoverable
          striped
          :loading="isLoading"
          class="mt-2 w-full"
          @sort="onSortTable"
        >
          <template #[`col.name`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass" :title="item.name">
              <asterix-column-router-link
                :item="item"
                :route="advertiserEdit"
                param-value="id"
                param-name="advertiserId"
              />
            </sun-data-table-cell>
          </template>
          <template #[`col.category.name`]="{ item }">
            <sun-data-table-cell>
              {{ item.category.name }}
            </sun-data-table-cell>
          </template>
          <template #[`col.subcategory.name`]="{ item }">
            <sun-data-table-cell>
              {{ item.subcategory.name }}
            </sun-data-table-cell>
          </template>
          <template #[`col.activeOffersCount`]="{ item }">
            <sun-data-table-cell class="text-center">
              {{ item.activeOffersCount }}
            </sun-data-table-cell>
          </template>

          <template #[`col.actions`]="{ item }">
            <sun-data-table-cell>
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>

        <sun-pagination-page
          :total-pages="totalPages"
          :total-items="totalItems"
          :current-page="currentPage"
          :value="itemsPerPage"
          :options-per-page="availablePageSizes"
          @changePage="goToPage({ page: $event })"
          @changePerPage="changeItemsPerPage"
        />
      </template>
    </asterix-section>

    <delete-modal :open="showModal" @cancel="closeDeleteModal" @confirm="deleteElement">
      <template #description> {{ deleteModalDescription }} </template>
    </delete-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ADVERTISER_RESOURCE } from '@/services/keys';
import { CATEGORIES, GET_CATEGORIES_REQUEST } from '@/store/modules/category/keys';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import AsterixSection from '@/components/templates/AsterixSection';
import { indexMixin } from '@/mixins/index/indexMixin';
import { deleteModalMixin } from '@/mixins/delete/deleteModal';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { getAdvertisers } from '@/services/modules/ecommerce/advertiser';
import { EcommerceApi } from '@/services/api/EcommerceApi';
import { create } from '@/router/private/modules/ecommerce/demand/advertiser/create';
import { deepClone } from '@/utils/deepClone';
import CONFIG from './config.js';
import { edit as advertiserEdit } from '@/router/private/modules/ecommerce/demand/advertiser/edit';
import { create as offerCreate } from '@/router/private/modules/ecommerce/demand/offer/create';
import AsterixColumnRouterLink from '@/components/atoms/AsterixColumnRouterLink';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'AdvertiserList',
  components: {
    AsterixSection,
    AsterixColumnRouterLink,
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    DeleteModal,
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: {
        name: undefined,
      },
    }),
    indexMixin,
    deleteModalMixin,
  ],
  data: () => ({
    headers: CONFIG.headers,
    actions: [{ name: 'Create an offer' }, { name: 'Edit' }, { name: 'Delete' }],
    items: [],
    // DeleteModal
    currentApi: new EcommerceApi(),
    deleteModalDescription: '',
    resource: ADVERTISER_RESOURCE,
    newAdvertiserRoute: create,
    advertiserEdit,
    availablePageSizes: [20, 50, 100],
  }),
  computed: {
    ...mapGetters({
      contextActive: ACTIVE_CONTEXT,
      categories: CATEGORIES,
    }),
    categoriesList() {
      return deepClone(this.categories);
    },
  },
  created() {
    this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
    this.getCategoriesAndSubcategories();
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
  },
  methods: {
    ...mapActions({ getCategoriesAndSubcategories: GET_CATEGORIES_REQUEST }),
    // TODO delete from here and index, only waiting delete from indexMixin

    async onActionClick(event, item) {
      switch (event.name) {
        case 'Create an offer':
          this.routeToCreateOffer(item);
          break;
        case 'Edit':
          this.routeToEditAdvertiser(item);
          break;
        case 'Delete':
          this.openDeleteAdvertiserModal(item);
          break;
      }
    },
    openDeleteAdvertiserModal(item) {
      this.deleteModalDescription = `Advertiser "${item.name}" is going to be deleted.`;
      this.openModal({ id: item.id });
    },
    routeToEditAdvertiser(item) {
      this.$router.push({ name: advertiserEdit.name, params: { advertiserId: item.id } });
    },
    routeToCreateOffer(item) {
      this.$router.push({
        name: offerCreate.name,
        query: { advertiserId: item.id },
      });
    },
    closeDeleteModal() {
      this.selectedId = null;
      this.showModal = false;
    },
    deleteElement() {
      this.deleteElementById();
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getAdvertisers);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
  },
};
</script>
