<template>
  <div>
    <meta-actions-header class="mb-4" :is-loading="isLoading">
      <template v-if="!isLoading" #header>
        <breadcrumb class="flex flex-1 text-gray-700"
          ><span>: </span>
          <span v-if="advertiser" class="text-orange-500">{{ advertiser.name }}</span>
        </breadcrumb>
      </template>
    </meta-actions-header>
    <div class="flex flex-col p-6 text-left bg-white rounded rounded-lg shadow-md lg:p-12">
      <card-form-loading v-if="isLoading || !advertiser" :rows="2" class="mt-10"></card-form-loading>

      <sun-form v-else @submit="submitForm" @keypress.enter="submitForm">
        <card-form>
          <template slot="title"> Basic Info</template>
          <template slot="form">
            <form-row>
              <template #left>
                <sun-label-group text="Name">
                  <asterix-input
                    id="advertiser-name"
                    v-model="advertiser.name"
                    type="text"
                    name="name"
                    :minlength="3"
                    :maxlength="50"
                    placeholder="3GFactor"
                    required="required"
                  />
                </sun-label-group>
              </template>
            </form-row>
            <category-selector v-model="advertiser" />
          </template>
        </card-form>
        <div class="flex w-1/2 mt-12">
          <save-button id="advertiser-submit" :loading="isLoading" />
          <sun-popover
            text-info="It is not possible to delete the selected advertiser entity because it is associated with an offer."
            :trigger="!canBeDeleted ? 'hover click' : 'null'"
          >
            <sun-button
              v-if="advertiserId"
              color="red"
              variant="pill"
              :disabled="isLoading || !canBeDeleted"
              class="px-6 ml-4 text-sm custom-p-1"
              @click="showDeleteModal = true"
            >
              Delete
            </sun-button>
          </sun-popover>
        </div>
      </sun-form>
    </div>

    <delete-modal :open="showDeleteModal" @cancel="showDeleteModal = false" @confirm="deleteAdvertiser">
      <template #description>{{ deleteAdvertiserText }}</template>
    </delete-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { CONTEXTS } from '@/model/shared/contexts';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import {
  createAdvertiser,
  deleteAdvertiser,
  getAdvertiserById,
  updateAdvertiser,
} from '@/services/modules/ecommerce/advertiser';
import { Toast } from '@/model/shared/Toast';
import Advertiser from '@/entities/ecommerce/Advertiser';
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import MetaActionsHeader from '@/components/organisms/shared/MetaActionsHeader';
import metaInfo from '@/mixins/common/metaInfo';
import SaveButton from '@/components/atoms/SaveButton';
import { list as listAdvertiserRoute } from '@/router/private/modules/ecommerce/demand/advertiser/list';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import AsterixInput from '@/components/atoms/AsterixInput';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import CategorySelector from '@/components/molecules/shared/CategorySelector';
import { CATEGORIES, GET_CATEGORIES_REQUEST } from '@/store/modules/category/keys';
import apiRequest from '@/utils/apiRequest';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'Form',
  components: {
    Breadcrumb,
    FormRow,
    CardForm,
    SaveButton,
    MetaActionsHeader,
    CardFormLoading,
    AsterixInput,
    CategorySelector,
    DeleteModal,
  },
  mixins: [metaInfo],
  props: {
    advertiserId: { type: String, default: () => null },
  },
  data: () => ({
    isLoading: false,
    advertiser: null,
    hasError: false,
    formErrors: {
      name: null,
      user: null,
    },
    showDeleteModal: false,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
      categories: CATEGORIES,
    }),
    deleteAdvertiserText() {
      return `The advertiser ${this.advertiser.name} is going to be deleted`;
    },
    canBeDeleted() {
      return !this.advertiser.offersCount;
    },
  },
  async created() {
    this.isLoading = true;
    if (this.advertiserId) {
      await this.getAdvertiser();
    } else {
      this.advertiser = new Advertiser(null, '', null, null, null, null, this.activeClient);
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST, getCategoriesAndSubcategories: GET_CATEGORIES_REQUEST }),
    metaTitleReplacement() {
      return { advertiser: this.advertiser?.name };
    },
    async getAdvertiser() {
      return apiRequest(async () => {
        const { data } = await getAdvertiserById(this.advertiserId);
        await this.getCategoriesAndSubcategories();
        const categoryWithSubCategories = this.categories.find(cat => cat.id === data.category?.id);
        data.category = categoryWithSubCategories;
        this.advertiser = data;

        this.updateTitle();
      }).catch(() => {
        this.hasError = true;
      });
    },
    async deleteAdvertiser() {
      try {
        this.isLoading = true;
        await deleteAdvertiser(this.advertiser.id);
        this.createToast(Toast.success('Delete done', `Delete of ${this.advertiser.name} was successfully`));
        await this.$router.replace({
          name: `${CONTEXTS.ECOMMERCE.baseUrl}-advertisers`,
        });
      } catch (error) {
        this.createToast(Toast.error('Delete was not possible', error.message));
        this.hasError = true;
      } finally {
        this.isLoading = false;
      }
    },

    async submitForm({ valid }) {
      if (!valid) return;
      try {
        this.isLoading = true;
        if (this.advertiserId) {
          await updateAdvertiser(this.advertiser);
          this.createToast(
            Toast.success('Advertiser updated', `Advertiser ${this.advertiser.name} was updated successfully.`)
          );
        } else {
          await createAdvertiser(this.advertiser);
          this.createToast(
            Toast.success('Advertiser created', `Advertiser ${this.advertiser.name} was created successfully.`)
          );
        }

        await this.$router.push(listAdvertiserRoute);
      } catch (error) {
        this.createToast(Toast.error('Advertiser not updated', error.message));
        this.hasError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
